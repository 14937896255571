export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11')
];

export const server_loads = [2];

export const dictionary = {
		"/[[lang=lang]]/about": [~6,[2]],
		"/admin": [10],
		"/admin/filer": [11],
		"/[[lang=lang]]/browse": [7,[2]],
		"/[[lang=lang]]/home": [~8,[2]],
		"/[[lang=lang]]/login-popup": [~9],
		"/[[lang=lang]]": [~4,[2]],
		"/[[lang=lang]]/[id]": [5,[2],[,3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';